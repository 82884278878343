import { isProd } from '@agria/utils';

const siteLinksDev = {
  brochureware: 'https://agria-ireland-brochure.netlify.app',
  quoteAndBuy: 'https://ireland-qab.netlify.app',
  quoteAndBuyContinueCover: 'https://ireland-qab.netlify.app/continue-cover',
  quoteAndBuyRetrieve: 'https://ireland-qab.netlify.app/retrieve',
  myAccount: 'https://ireland-qab.netlify.app/my-account/',
  importantDocuments:
    'https://agria-ireland-brochure.netlify.app/important-documents/',
} as const;

const siteLinksProduction = {
  ...siteLinksDev,
  brochureware: 'https://agria.ie',
  myAccount: 'https://agria.ie/my-account/',
  quoteAndBuyContinueCover: 'https://quote.agria.ie/continue-cover',
  quoteAndBuyRetrieve: 'https://quote.agria.ie/retrieve',
  quoteAndBuy: 'https://quote.agria.ie',
  importantDocuments: 'https://agria.ie/important-documents/',
};
export const siteLinks = isProd ? siteLinksProduction : siteLinksDev;
